<div class="status">
  <span *ngIf="onlineStatus === 'online'" 
    class="tw-px-6 tw-py-2 tw-rounded-full tw-bg-green-50 tw-text-green-700 tw-font-bold tw-border tw-border-solid tw-border-gray-500/20">
    Online
  </span>
  <span *ngIf="onlineStatus === 'offline'" 
    class="tw-px-6 tw-py-2 tw-rounded-full tw-bg-red-50 tw-text-red-700 tw-font-bold tw-border tw-border-solid tw-border-gray-500/20">
    Offline
  </span>
  <span *ngIf="onlineStatus === 'not_activated'" 
    class="tw-px-6 tw-py-2 tw-rounded-full tw-bg-yellow-50 tw-text-yellow-700 tw-font-bold tw-border tw-border-solid tw-border-gray-500/20">
    Not Activated
  </span>
</div> 