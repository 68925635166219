<ng-template #jamfPopover>Find your credentials on
  <a class="madero-link u_clickable" [href]="JAMF_URL" target="_blank">JAMF Pro</a>.
</ng-template>
<!-- TODO: update URL for Mosyle Support -->
<ng-template #mosylePopover>Find your credentials on
  <a class="madero-link u_clickable" [href]="JAMF_URL" target="_blank">Mosyle</a>.
</ng-template>
<ng-template #ssoUserProvisioningPopover>
  <span class="instructions">Enabling this setting manages users through your IdP and requires SSO for all users.</span><br/>
  <span class="instructions">Please confirm that all users have the appropriate user roles assigned through the IdP. Users who do not have user roles will not have access to Rise Vision.</span>
</ng-template>
@if (!company.id) {
<div class="form-group required" [ngClass]="{'has-error': (name.dirty || companyForm?.submitted) && name.invalid }">
  <label for="company-settings-name" class="control-label">
    Company Name:
  </label>
  <input #name="ngModel" required id="company-settings-name" type="text" class="form-control" [(ngModel)]="company.name"
    name="name">
  <p [shown]="(name.dirty || companyForm?.submitted) && name.invalid"
    class="help-block validation-error-message-name text-danger">Company Name is required.</p>
</div>
}
<div class="row">
  <div class="{{showManagedSections ? 'col-md-8' : 'col-md-12'}} mb-4">
    <div class="row">
      <div class="col-md-12 mb-4">
        <div class="pl-4 pr-4 pt-1 border-container">
          <div class="row">
            <div class="col-md-12 mb-2">
              <h5>Contact Information</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group required"
                [ngClass]="{'has-error': (companyIndustry.dirty || companyForm?.submitted) && companyIndustry.invalid }">
                <label for="company-industry" class="control-label">
                  Industry:
                </label>
                <select #companyIndustry="ngModel" id="company-industry" class="form-control selectpicker"
                  [disabled]="disableIndustry" [(ngModel)]="company.companyIndustry" name="companyIndustry" required>
                  <option [value]="undefined" hidden>&lt; Select Industry &gt;</option>
                  @for (industry of COMPANY_INDUSTRY_FIELDS; track industry) {
                  <option value="{{industry[1]}}">{{industry[0]}}</option>
                  }
                </select>
                <p [shown]="(companyIndustry.dirty || companyForm?.submitted) && companyIndustry.invalid"
                  class="help-block validation-error-message-name text-danger">Please select an Industry.</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="company-size" class="control-label">
                  Company Size:
                </label>
                <select #companySize name="companySize" id="company-size" class="form-control"
                  [(ngModel)]="company.companySize">
                  <option [value]="undefined" hidden>&lt; Select Size &gt;</option>
                  @for (size of COMPANY_SIZE_FIELDS; track size) {
                  <option value="{{size[1]}}">{{size[0]}}</option>
                  }
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="company-settings-street" class="control-label">
                  Street:
                </label>
                <input #street id="company-settings-street" type="text" class="form-control"
                  [(ngModel)]="company.street" name="street" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="company-settings-unit" class="control-label">
                  Unit:
                </label>
                <input #unit id="company-settings-unit" type="text" class="form-control" [(ngModel)]="company.unit"
                  name="unit" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="company-settings-city" class="control-label">
                  City:
                </label>
                <input #city id="company-settings-city" type="text" class="form-control" [(ngModel)]="company.city"
                  name="city" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="company-settings-country" class="control-label">
                  Country:
                </label>
                <select #country id="company-settings-country" class="form-control selectpicker"
                  [(ngModel)]="company.country" (change)="detectChanges()" name="country">
                  <option [value]="undefined" hidden>&lt; Select Country &gt;</option>
                  @for (c of countries; track c) {
                  <option [value]="c.code" [disabled]="c.code === null">{{c.name}}</option>
                  }
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="company-settings-state" class="control-label u_ellipsis u_align-top">
                  State/Province/Region:
                </label>
                @if (company.country !== 'US' && company.country !== 'CA') {
                <input #region id="company-settings-state" type="text" class="form-control"
                  [(ngModel)]="company.province" name="province" />
                }
                @else if (company.country === 'CA') {
                <select #province class="form-control selectpicker" [(ngModel)]="company.province" name="province">
                  <option [value]="undefined" hidden>&lt; Select Province &gt;</option>
                  @for (c of regionsCA; track c) {
                  <option [value]="c[1]">{{c[0]}}</option>
                  }
                </select>
                }
                @else if (company.country === 'US') {
                <select #state class="form-control selectpicker" [(ngModel)]="company.province" name="province">
                  <option [value]="undefined" hidden>&lt; Select State &gt;</option>
                  @for (c of regionsUS; track c) {
                  <option [value]="c[1]">{{c[0]}}</option>
                  }
                </select>
                }
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="company-settings-zip" class="control-label">
                  ZIP/Postal Code:
                </label>
                <input #postalCode id="company-settings-zip" type="text" class="form-control"
                  [(ngModel)]="company.postalCode" name="postalCode" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" [ngClass]="{'has-error': website.errors?.pattern}">
                <label for="company-settings-website" class="control-label">
                  Website:
                </label>
                <input #website="ngModel" id="company-settings-website" name="website" type="text"
                  placeholder="https://" class="form-control" [(ngModel)]="company.website" [pattern]="WEBSITE_REGEX" />
                <p [shown]="website.errors?.pattern" class="help-block validation-error-message-website text-danger">
                  Please provide a valid URL.</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="company-settings-phone" class="control-label">
                  Phone Number:
                </label>
                <input #telephone name="telephone" id="company-settings-phone" type="tel" class="form-control"
                  [(ngModel)]="company.telephone" />
              </div>
            </div>
          </div>
          <div class="form-group" [ngClass]="{'has-error': billingContactEmails.invalid}">
            <label class="control-label">Billing Notifications Email:</label>
            <emails-field #billingContactEmails="ngModel" name="billingContactEmails"
              [(ngModel)]="company.billingContactEmails" [requireEmailsOnChange]="true"></emails-field>
            <p class="help-block text-danger" [shown]="billingContactEmails.errors?.emails">Please provide a valid
              email.</p>
            <p class="help-block text-danger" [shown]="billingContactEmails.errors?.requireEmails">Please enter at least
              one email.</p>
          </div>
        </div>
      </div>
    </div>
    @if (showManagedSections) {
    <div class="row">
      <div class="col-md-12">
        <div class="pl-4 pr-4 pt-4 border-container">
          <div class="row">
            <div class="col-md-6 pr-5">
              <div class="form-group">
                <label>
                  Authentication Key:
                </label>
                <a class="action-link ps-reset-auth-key ml-2 madero-link pull-right" href="#" id="resetAuthKey"
                  (click)="resetAuthKey()">Reset</a>
                <div class="ps-auth-key">
                  {{company.authKey}}
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-5">
              <div class="form-group">
                <label>
                  Claim ID:
                </label>
                <a class="action-link ps-reset-claim-id ml-2 madero-link pull-right" href="#" id="claimId"
                  (click)="resetClaimId()">Reset</a>
                <div class="ps-claim-id">
                  {{company.claimId}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
  </div>
  @if (showManagedSections) {
  <div class="col-md-4">
    <div class="pl-4 pr-4 pt-1 mb-4 border-container">
      <div class="row">
        <div class="col-md-12 mb-2">
          <h5>FUSE Display Management</h5>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <div class="flex-row left-right-aligner">
            <label id="company-settings-fuseenabled-label" for="company-settings-fuseenabled" class="control-label">
              Enable Avocor FUSE
            </label>
            <mat-slide-toggle class="mb-2" name="fuseEnabled" id="company-settings-fuseenabled"
              aria-labelledby="company-settings-fuseenabled-label" [(ngModel)]="company.settings.fuseEnabled"
              (change)="updateEditableFields()">
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      @if (company.settings.fuseEnabled) {
      <div>
        <div class="row pt-3 border-top">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-fuseapikey" class="control-label">
                FUSE API Key:
              </label>
              <div class="flex-row left-right-aligner">
                <input id="company-settings-fuseapikey" name="company-settings-fuseapikey" #fuseapikey="ngModel"
                  required type="text" class="form-control" placeholder="API Key" [readonly]="!fuseApiKeyEditable"
                  [(ngModel)]="company.settings.fuseApiKey">
                @if (!fuseApiKeyEditable) {
                <button aria-label="Edit FUSE API Key" class="btn btn-default-icon pl-3 pr-3 ml-2"
                  (click)="editFuseApiKey()">
                  <streamline-icon ariaLabel="Edit FUSE API Key" name="pencil" width="12" height="12"></streamline-icon>
                </button>
                }
              </div>
              <p [shown]="(fuseapikey.dirty || companyForm?.submitted) && fuseapikey.errors?.required"
                class="help-block validation-error-message-name text-danger">FUSE API Key is required.</p>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <span class="instructions">
              Enter your API Key from <a href="https://fuse.avocor.com/settings/api_keys?page=1"
                target="_blank">https://fuse.avocor.com/settings/api_keys?page=1</a>
            </span>
          </div>
        </div>
      </div>
      }
    </div>
    <div class="pl-4 pr-4 pt-1 mb-4 border-container">
      <div class="row">
        <div class="col-md-12 mb-2">
          <h5>Apple TV MDM</h5>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <div class="flex-row left-right-aligner">
            <label id="company-settings-jamfenabled-label" for="company-settings-jamfenabled" class="control-label">
              Enable JAMF Pro
              <streamline-icon tabindex="0" name="help" class="help" [outsideClick]="true" [popover]="jamfPopover"
                triggers="mouseenter:click focus:click" (keyup.enter)="redirect()"
                ariaLabel="Show Enable JAMF Pro Tooltip">
              </streamline-icon>
            </label>
            <mat-slide-toggle tabindex="0" class="mb-2" name="jamfEnabled" id="company-settings-jamfenabled"
              aria-labelledby="company-settings-jamfenabled-label" [(ngModel)]="company.settings.jamfEnabled"
              (change)="updateEditableFields()" [disabled]="company.settings.mosyleEnabled">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <div class="flex-row left-right-aligner">
            <label id="company-settings-mosyleenabled-label" for="company-settings-mosyleenabled" class="control-label">
              Enable Mosyle
              <streamline-icon tabindex="0" name="help" class="help" [outsideClick]="true" [popover]="mosylePopover"
                triggers="mouseenter:click focus:click" (keyup.enter)="redirect()"
                ariaLabel="Show Enable Mosyle Tooltip">
              </streamline-icon>
            </label>
            <mat-slide-toggle tabindex="0" class="mb-2" name="mosyleEnabled" id="company-settings-mosyleenabled"
              aria-labelledby="company-settings-mosyleenabled-label" [(ngModel)]="company.settings.mosyleEnabled"
              (change)="updateEditableFields()" [disabled]="company.settings.jamfEnabled">
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      @if (company.settings.mosyleEnabled) {
      <div>
        <div class="row pt-3 border-top">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-mosyleadminemail" class="control-label">
                Admin Email:
              </label>
              <input id="company-settings-mosyleadminemail" name="company-settings-mosyleadminemail"
                #mosyleadminemail="ngModel" required type="text" class="form-control" [pattern]="EMAIL_REGEX"
                placeholder="Admin Email" [(ngModel)]="company.settings.mosyleEmail">
              <p [shown]="(mosyleadminemail.dirty || companyForm?.submitted) && mosyleadminemail.errors?.required"
                class="help-block validation-error-message-admin-email text-danger">Mosyle Admin Email is required.</p>
              <p [shown]="(mosyleadminemail.dirty || companyForm?.submitted) && mosyleadminemail.errors?.pattern"
                class="help-block validation-error-message-admin-email text-danger">Mosyle Admin Email is invalid.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-mosylepassword" class="control-label">
                Password:
              </label>
              <input id="company-settings-mosylepassword" name="company-settings-mosylepassword"
                #mosylepassword="ngModel" required type="password" class="form-control" placeholder="Password"
                [(ngModel)]="company.settings.mosylePassword">
              <p [shown]="(mosylepassword.dirty || companyForm?.submitted) && mosylepassword.errors?.required"
                class="help-block validation-error-message-password text-danger">Mosyle Password is required.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-mosyleaccesstoken" class="control-label">
                Access Token:
              </label>
              <div class="flex-row left-right-aligner">
                <input id="company-settings-mosyleaccesstoken" name="company-settings-mosyleaccesstoken"
                  #mosyleaccesstoken="ngModel" required type="text" class="form-control" placeholder="Access Token"
                  [readonly]="!mosyleAccessTokenEditable" [(ngModel)]="company.settings.mosyleAccessToken">
                @if (!mosyleAccessTokenEditable) {
                <button aria-label="Edit mosyle access token" class="btn btn-default-icon pl-3 pr-3 ml-2"
                  (click)="editMosyleAccessToken()">
                  <streamline-icon ariaLabel="Edit mosyle access token" name="pencil" width="12"
                    height="12"></streamline-icon>
                </button>
                }
              </div>
              <p [shown]="(mosyleaccesstoken.dirty || companyForm?.submitted) && mosyleaccesstoken.errors?.required"
                class="help-block validation-error-message-access-token text-danger">Mosyle Access Token is required.
              </p>
            </div>
          </div>
        </div>
      </div>
      }
      @if (company.settings.jamfEnabled) {
      <div>
        <div class="row pt-3 border-top">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-jamfdomain" class="control-label">
                JAMF Pro Server:
              </label>
              <input id="company-settings-jamfdomain" name="company-settings-jamfdomain" #jamfdomain="ngModel" required
                type="text" class="form-control" [pattern]="WEBSITE_REGEX" placeholder="Domain"
                [(ngModel)]="company.settings.jamfDomain">
              <p [shown]="(jamfdomain.dirty || companyForm?.submitted) && jamfdomain.errors?.required"
                class="help-block validation-error-message-domain text-danger">Jamf Domain is required.</p>
              <p [shown]="(jamfdomain.dirty || companyForm?.submitted) && jamfdomain.errors?.pattern"
                class="help-block validation-error-message-domain text-danger">Jamf Domain is invalid.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-jamfclientid" class="control-label">
                Client ID:
              </label>
              <input id="company-settings-jamfclientid" name="company-settings-jamfclientid" #jamfclientid="ngModel"
                required type="text" class="form-control" placeholder="Client Id"
                [(ngModel)]="company.settings.jamfClientId">
              <p [shown]="(jamfclientid.dirty || companyForm?.submitted) && jamfclientid.errors?.required"
                class="help-block validation-error-message-client-id text-danger">Jamf Client Id is required.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-jamfclientsecret" class="control-label">
                Client Secret:
              </label>
              <div class="flex-row left-right-aligner">
                <input id="company-settings-jamfclientsecret" name="company-settings-jamfclientsecret"
                  #jamfclientsecret="ngModel" required type="text" class="form-control" placeholder="Client Secret"
                  [readonly]="!jamfSecretEditable" [(ngModel)]="company.settings.jamfClientSecret">
                @if (!jamfSecretEditable) {
                <button aria-label="Edit JAMF client secret" class="btn btn-default-icon pl-3 pr-3 ml-2"
                  (click)="editJamfSecret()">
                  <streamline-icon ariaLabel="Edit JAMF client secret" name="pencil" width="12"
                    height="12"></streamline-icon>
                </button>
                }
              </div>
              <p [shown]="(jamfclientsecret.dirty || companyForm?.submitted) && jamfclientsecret.errors?.required"
                class="help-block validation-error-message-name text-danger">Jamf Client Secret is required.</p>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    @if (isRiseStoreAdmin && showManagedSections) {
    <div>
      <div class="pl-4 pr-4 pt-1 pb-4 mb-4 border-container">
        <div class="row">
          <div class="col-md-12">
            <h5>Rise Admin Only</h5>
          </div>
        </div>
        <div class="flex-row left-right-aligner mt-3">
          <label id="is-test-label" for="is-test">Test Company</label>
          <mat-slide-toggle name="isTest" id="is-test" aria-labelledby="is-test-label" class="mb-2"
            [(ngModel)]="company.isTest" [disabled]="true">
          </mat-slide-toggle>
        </div>
        <div class="flex-row left-right-aligner mt-3">
          <label id="share-company-plan-label" for="share-company-plan">Share Company Plan</label>
          <mat-slide-toggle name="shareCompanyPlan" id="share-company-plan" aria-labelledby="share-company-plan-label"
            class="mb-2" [(ngModel)]="company.shareCompanyPlan" [disabled]="disableShareCompanyPlan()">
          </mat-slide-toggle>
        </div>
        @if (!(currentPlanService.isUnlimitedPlan() && currentPlanService.isSubscribed())) {
        <div class="flex-row left-right-aligner mt-3">
          <label id="unlock-all-features-label" for="unlock-all-features" class="rise-admin-only">Unlock All
            Features</label>
          <mat-slide-toggle name="unlockAllFeatures" id="unlock-all-features"
            aria-labelledby="unlock-all-features-label" class="mb-2" [(ngModel)]="company.unlockAllFeatures">
          </mat-slide-toggle>
        </div>
        }
      </div>
    </div>
    }
  </div>
  }
</div>