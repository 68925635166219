import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DisplayService } from '../../services/display.service';
import { ScreenshotService } from '../../services/screenshot.service';
import { DisplayControlService } from '../../services/display-control.service';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { ActionsMenu } from 'src/app/components/common-actions/common-actions.component';
import { ModalService } from 'src/app/components/modals/modal.service';
import { PlayerProService } from '../../services/player-pro.service';
import { PlayerActionsService } from '../../services/player-actions.service';
import { DisplayFieldsComponent } from '../display-fields/display-fields.component';
import { DisplayStatusComponent } from '../display-status/display-status.component';

@Component({
    selector: 'display-details',
    templateUrl: './display-details.component.html',
    styleUrls: ['./display-details.component.scss'],
    standalone: false
})
export class DisplayDetailsComponent implements OnInit {

  selectedSchedule: any = null;

  displayControlType: string = 'hdmi-cec';

  @ViewChild('displayForm') displayForm: NgForm;
  @ViewChild(DisplayFieldsComponent) displayFields: DisplayFieldsComponent;
  actions: ActionsMenu = {};
  displayStatusComponent = DisplayStatusComponent;

  constructor(private ngZone: NgZone,
    public displayService: DisplayService,
    public displayControlService: DisplayControlService,
    private screenshotService: ScreenshotService,
    private uiRouterGlobals: UIRouterGlobals,
    private stateService: StateService,
    private modalService: ModalService,
    private playerActionsService: PlayerActionsService,
    private playerProService: PlayerProService
  ) { }

  ngOnInit(): void {
    const displayId = this.uiRouterGlobals.params.displayId;
    this.displayService.init();
    this.displayService.getDisplay(displayId).then(() => {
      if (this.displayService.hasSchedule(this.displayService.display)) {
        this.selectedSchedule = {
          id: this.displayService.display.scheduleId,
          name: this.displayService.display.scheduleName,
          companyId: this.displayService.display.companyId
        };
      }

      if (!this.displayService.display.playerProAuthorized) {
        this.displayService.display.monitoringEnabled = false;
      }

      if (this.displayService.display.onlineStatus === "online") {
        this.screenshotService.requestScreenshot();
      } else {
        this.screenshotService.loadScreenshot();
      }

      this.displayControlService.isDisplayControlEnabled(displayId).then((enabled) => {
        this.displayService.display.isDisplayControlEnabled = enabled;
      });

      this.displayControlService.getInterfaceType(this.displayService.display.id).then((type) => {
        this.displayControlType = type;
      });

      this.actions = {
        saveAction: {
          id: 'saveButton',
          label: 'Save',
          disabled: this.displayForm.invalid || this.displayService.savingDisplay,
          requireRole: 'da'
        },
        cancelAction: {
          id: 'cancelButton',
          label: 'Cancel',
          action: () => this.stateService.go('apps.displays.list')
        },
        deleteAction: {
          id: 'deleteButton',
          label: 'Delete',
          action: () => this.confirmDelete(),
          disabled: !this.displayService.display.id,
          requireRole: 'da'
        },
        moreOptions: [
          {
            id: 'restartButton',
            label: 'Restart Rise Player',
            action: () => this.playerActionsService.confirm(
              this.displayService.display.id,
              this.displayService.display.name,
              'restart'
            ),
            disabled: this.displayService.display.onlineStatus === 'not_activated' ||
              !this.playerProService.isRestartCompatiblePlayer(this.displayService.display) ||
              !this.displayService.display.playerVersion,
            requireRole: 'da'
          },
          {
            id: 'rebootButton',
            label: 'Reboot Media Player',
            action: () => this.playerActionsService.confirm(
              this.displayService.display.id,
              this.displayService.display.name,
              'reboot'
            ),
            disabled: this.displayService.display.onlineStatus === 'not_activated' ||
              !this.playerProService.isRebootCompatiblePlayer(this.displayService.display) ||
              !this.displayService.display.playerVersion,
            requireRole: 'da'
          },
          {
            id: 'installDisplayButton',
            label: 'Install Display',
            action: () => this.stateService.go('apps.displays.install')
          }
        ]
      };

      setTimeout(() => {
        this.ngZone.run(() => {});
      });
    });
  }

  save() {
    if (!this.displayForm.valid) {
      console.info('form not valid: ', this.displayForm.errors);

      return Promise.reject();
    } else {
      return this.displayService.updateDisplay(this.selectedSchedule);
    }
  }

  confirmDelete () {
    this.modalService.confirmDanger('Delete Display?',
        'Are you sure you want to delete this Display?',
        'Yes', 'No')
      .then(() => { return this.displayService.deleteDisplay(); });
  }
}
