<div class="tw-flex tw-flex-col xl:!tw-flex-row xl:!tw-justify-between tw-pr-2">
  <div class="tw-flex tw-flex-row tw-items-center tw-gap-8 tw-w-1/2">
    <label class="tw-text-gray-700 tw-font-bold tw-mb-0 tw-w-36" [hidden]="!showLabel">Timeline</label>
    <mat-slide-toggle [shown]="!readonly" class="tw-mr-1" id="alwaysCheckbox" aria-labelledby="timelineAlwaysLabel"
      (ngModelChange)="toggleAlways($event)" [ngModel]="timeline.always">
      <span id="timelineAlwaysLabel" class="tw-ml-2 tw-cursor-pointer " [shown]="!readonly || timeline.always">Show all
        the time.</span>
    </mat-slide-toggle>
  </div>
  <span tabindex="0" id="timelineLabelMadero" class="tw-w-1/2"
    [ngClass]="{'tw-text-blue-600 tw-cursor-pointer hover:tw-text-blue-800 tw-flex tw-items-center tw-justify-between tw-gap-4 tw-break-words tw-max-w-full': !readonly}" (click)="openModal()"
    (keyup.enter)="openModal()" [shown]="!timeline.always" [innerHtml]="timeline.label"></span>
</div>