import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'display-status',
  templateUrl: './display-status.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class DisplayStatusComponent {
  @Input() onlineStatus: string;
} 