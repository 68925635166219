<div id="share-schedule-button" class="u_clickable" (click)="openMenu()">
  <a
    class="hidden-xs"
    [ngClass]="buttonClass"
    [matMenuTriggerFor]="shareMenu"
    mat-stroked-button
  >
    Share
  </a>
</div>
<mat-menu #shareMenu="matMenu" xPosition="before" class="share-panel" panelClass="share-panel">
  <shared-schedule-popover #sharePopover (dismissEvent)="dismiss()"></shared-schedule-popover>
</mat-menu>

<button
  id="actionSheetButton"
  type="button"
  class="visible-xs-inline"
  [ngClass]="buttonClass"
  (click)="openActionSheet()"
  mat-stroked-button
>
  Share
</button>
