<div class="form-group mt-4">
  <div class="tw-flex tw-items-center tw-gap-8">
    <label class="tw-text-gray-700 tw-font-bold tw-mb-0 tw-w-36 tw-mr-4">Override</label>
    <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
      <div class="schedule-type-value" [shown]="readonly">
        <span>{{ description }}</span>
      </div>
      <div class="tw-flex tw-gap-6 xl:!tw-items-center xl:!tw-justify-between tw-flex-col xl:!tw-flex-row" [shown]="!readonly">
        <mat-radio-group name="schedule-type-options" class="tw-flex tw-flex-row" [(ngModel)]="scheduleType" (change)="detectChanges()">
          <mat-radio-button
            class="tw-mr-1" 
            id="schedule-is-default" 
            name="scheduleIsDefault" 
            value="default">
            None
            </mat-radio-button>
            <mat-radio-button 
              class="tw-mr-1"
              id="schedule-override-replace" 
              name="scheduleOverrideTypeReplace" 
              value="override_replace"
              [disabled]="!isOverrideAvailable">
              Replace
              </mat-radio-button>
              <mat-radio-button 
                id="schedule-override-include" 
                name="scheduleOverrideTypeInclude" 
                value="override_insert"
                [disabled]="!isOverrideAvailable">
              Include
          </mat-radio-button>
        </mat-radio-group>
        <div class="tw-text-gray-500 tw-w-full tw-break-words tw-ml-4" [shown]="isOverrideAvailable">
          <span [shown]="scheduleType === 'default'">
            This schedule is the default display content.
          </span>
          <span [shown]="scheduleType === 'override_insert'">
            This schedule will be included with the default display content.
          </span>
          <span [shown]="scheduleType === 'override_replace'">
            This schedule will replace the default display content.
          </span>
        </div>
        <div class="flex-row w-100 mt-2" [shown]="false && scheduleType === 'override_replace'" *requireRole="'sa'">
          <mat-checkbox checkboxId="schedule-play-once" [(ngModel)]="playOnce" ngDefaultControl (ngModelChange)="detectChanges()" [disabled]="!isOverrideAvailable"></mat-checkbox>
          <label for="schedule-play-once" class="rise-admin-only control-label-secondary">Play once</label>
        </div>
      </div>
      <div class="schedule-type-value mt-2" [shown]="!isOverrideAvailable">
        <span [shown]="isOverrideAvailable === null">
          Schedule Override feature requires an active subscription.
          <a class="madero-link" href="#" uiSref="apps.purchase.home">Subscribe Now</a>
        </span>
        <span [shown]="isOverrideAvailable !== null">
          Schedule Override feature requires a plan upgrade.
          <a class="madero-link" href="#" (click)="showUpgradePlanModal()">Upgrade Plan</a>
        </span>
      </div>
    </div>
  </div>
</div>
