import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as _ from 'lodash';

import { ModalService } from 'src/app/components/modals/modal.service';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { UserAuthService } from 'src/app/auth/services/user-auth.service';
import { E } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  loading = false;
  company: any = {};
  apiError: string;
  formError: string;
  isAddressError = false;

  constructor(
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private userAuthService: UserAuthService,
    private companyApiService: CompanyApiService,
    private addressFactory: AddressService,
    private tracker: TrackerService,
    private processErrorCode: ProcessErrorCode,
    private modalService: ModalService,
    private apiUtils: ApiUtilsService,
  ) {
    this._clearErrorMessages();
  }

  private _verifyAdmin(company: any) {
    if (!this.userStateService.isRiseStoreAdmin()) {
      delete company.shareCompanyPlan;
      delete company.unlockAllFeatures;
    }
  }

  private _clearErrorMessages() {
    this.formError = null;
    this.apiError = null;
    this.isAddressError = false;
  }

  _showErrorMessage(action, error) {
    this.formError = 'Failed to ' + action + ' Company.';
    this.apiError = this.processErrorCode(error);
  }

  getCompany(companyId: string) {
    if (companyId) {
      this.loading = true;
      this.companyApiService.getCompany(companyId)
        .then((company) => {
          this.company = company;
          this.company.isChargebee = company && company.origin === 'Chargebee';
        })
        .catch((resp) => {
          this._showErrorMessage('load', resp);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  save(companyForm: NgForm) {
    this._clearErrorMessages();

    this.loading = true;

    return this.addressFactory.validateAddressIfChanged(companyForm.form.controls, this.company)
      .then(() => {
        const companyCopy = _.cloneDeep(this.company);

        this._verifyAdmin(companyCopy);
        return this.companyApiService.updateCompany(this.company.id, companyCopy)
          .then((response) => {
            this.tracker.companyEvent('Company Updated', this.companyStateService.getSelectedCompanyId(),
              this.companyStateService.getSelectedCompanyName(), !this.companyStateService.isSubcompanySelected());

            this.companyStateService.updateCompanySettings(this.company);
          })
          .catch((error) => {
            this._showErrorMessage('update', error);
          });
      })
      .catch((error) => {
        this.formError = 'We couldn\'t update your address.';
        this.apiError = this.processErrorCode(error);
        this.isAddressError = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  deleteCompany(company: any) {
    this.loading = true;

    return this.companyApiService.deleteCompany(company.id)
      .then(() => {
        this.tracker.companyEvent('Company Deleted', this.companyStateService.getSelectedCompanyId(),
          this.companyStateService.getSelectedCompanyName(), !this.companyStateService.isSubcompanySelected());

        if (this.companyStateService.getUserCompanyId() === company.id) {
          this.userAuthService.signOut();
        } else if (this.companyStateService.getSelectedCompanyId() === company.id) {
          this.companyStateService.resetCompany();
        }
      })
      .catch((error) => {
        if (this.apiUtils.getError(error).message === 'Company has active subscriptions and cannot be deleted.') {
          const message1 = 'You have an active subscription and cannot delete your company.';
          const message2 = 'Please contact <a href="mailto:help@risevision.com">help@risevision.com</a> for assistance.';

          this.modalService.showMessage('You have an active subscription.', `${message1} ${message2}`);
        } else {
          this._showErrorMessage('delete', error);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  resetCompanyField(type) {
    this.loading = true;

    return this.companyApiService.regenerateField(this.company.id, type)
      .catch((error) => {
        this.apiError = this.processErrorCode(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  exportCompany(company?: any) {
    return this.modalService.confirm('Export companies?',
      'An export file will be prepared and emailed to you at <b>' + this.userStateService.getUserEmail() +
      '</b> once ready.<br/> Please ensure your email is configured to accept emails from <b>no-reply@risevision.com</b>.',
      'Export', 'Cancel').then(() => {
        this.loading = true;
        return this.companyApiService.exportCompany(company ? company.id : this.company.id)
          .catch((error) => {
            if (error) {
              this._showErrorMessage('export', error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
    });
  }
}
