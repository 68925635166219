<div class="tw-flex tw-flex-col xl:!tw-flex-row xl:!tw-justify-between">
  <div class="tw-flex tw-flex-row tw-w-1/2">
    <div class="tw-flex tw-flex-row tw-items-center tw-gap-8">
      <label class="tw-text-gray-700 tw-font-bold tw-mb-0 tw-w-36" [hidden]="!showLabel">Distribution</label>

      <mat-slide-toggle [shown]="!readonly" class="tw-mr-1" id="allDisplaysCheckbox" (change)="outputDistributeToAll()" [(ngModel)]="distributeToAll" aria-labelledby="distributeToAllLabel">
        <span id="distributeToAllLabel" class="tw-ml-2 tw-cursor-pointer" [shown]="!readonly || distributeToAll">Show on all displays{{ enableSubcompanyDistributeToAll ? ' in this company' : ''}}.</span>
      </mat-slide-toggle>
      @if (readonly && !distributeToAll) {
        <span id="distributeToAllLabel">{{ distributionSelectionMessage }}</span>
      }
    </div>
  </div>
  <span id="distributionFieldMadero" class="tw-text-blue-600 tw-cursor-pointer hover:tw-text-blue-800 tw-flex tw-items-end tw-w-1/2" timeline="timeline" (click)="manage()" [shown]="!readonly && !distributeToAll" tabindex="0" (keyup.enter)="manage()">{{ distributionSelectionMessage }}</span>
  @if (subcompanyDistributeToAllAvailable) {
    <div class="tw-flex tw-flex-row">
      <div class="tw-flex tw-flex-row tw-items-center">
        <mat-slide-toggle [shown]="!readonly" class="tw-mr-1" id="allSubcompanyDisplaysCheckbox" (change)="outputSubcompanyDistributeToAll()" [(ngModel)]="subcompanyDistributeToAll" aria-labelledby="subcompanyDistributeToAllLabel">
          <span id="subcompanyDistributeToAllLabel" class="tw-ml-2 tw-cursor-pointer">Show on all displays in sub companies.</span>
        </mat-slide-toggle>
      </div>
    </div>
  }
</div>
