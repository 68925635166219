import { Component, Input, Output, EventEmitter, Type } from '@angular/core';

export interface MenuAction {
  id: string;
  label: string;
  action?: () => void;
  requireRole?: string;
  disabled?: boolean;
}

export interface ActionsMenu {
  saveAction?: {
    id: string;
    label: string;
    action?: () => void;
    requireRole?: string;
    disabled: boolean | (() => boolean);
  };
  cancelAction?: {
    id: string;
    label: string;
    action: () => void;
  };
  deleteAction?: MenuAction;
  moreOptions?: MenuAction[];
}

@Component({
    selector: 'common-actions',
    templateUrl: './common-actions.component.html',
    standalone: false
})
export class CommonActionsComponent {
  private _title: string;

  @Input()
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
    this.titleChange.emit(value);
  }
  @Output() titleChange = new EventEmitter<string>();

  @Input() actions: ActionsMenu;
  @Input() saving = false;
  @Input() changeDate?: Date;
  @Input() changedBy?: string;
  @Input() customComponent?: Type<any>;
  @Input() componentInputs?: { [key: string]: any };
}
